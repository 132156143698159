import React from "react";
import float_van from "../../Assets/Images/Services/Webp/Float-Van.webp";
import btl_marketing from "../../Assets/Images/Services/Webp/Btl.webp";
import float_setup from "../../Assets/Images/Services/Webp/Float-Setup.webp";
import market_setup from "../../Assets/Images/Services/Webp/Market-Setup.webp";
import product_launch from "../../Assets/Images/Services/Webp/Product-Launch.webp";
import corporate_events from "../../Assets/Images/Services/Webp/Corporate.webp";
import marketing_survey from "../../Assets/Images/Services/Webp/Marketing- Survey.webp";
import test_drive from "../../Assets/Images/Services/Webp/Test-Drive.webp";
import residential from "../../Assets/Images/Services/Webp/Residential-Campaign.webp";
import digital_marketing from "../../Assets/Images/Services/Webp/Digital-Marketing.webp";
import creative_design from "../../Assets/Images/Services/Webp/Creative-Design.webp";
import holding from "../../Assets/Images/Services/Webp/Holding-Paint.webp";
import wall_wrap from "../../Assets/Images/Services/Webp/Wall-Wrap.webp";
import flex_banner from "../../Assets/Images/Services/Webp/Flex-Banners.webp";
import mug_t_shirt from "../../Assets/Images/Services/Webp/Mug-Tshirt.webp";
import leaflets from "../../Assets/Images/Services/Webp/Leaflets-Look-Walkers.webp";
import rwa_activations from "../../Assets/Images/Services/Webp/Rwa-Activation.webp";
import mall_activations from "../../Assets/Images/Services/Webp/Mall-Activation.webp";

const OurActivity = () => {
  return (
    <div className="bg-[#f4f4f4]">
      <div className=" max-w-[1440px] mx-auto px-4 pt-10 md:pt-[78px]">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 flex justify-center">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={float_van}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Float Van Campaign
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Promote your brand on the move with eye-catching, mobile
                advertising that grabs attention in every corner.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 sm:mt-0">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={btl_marketing}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                BTL Marketing
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Target specific audiences through creative and cost-effective
                below-the-line marketing strategies.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={float_setup}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Float Setup
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Transform vehicles into dynamic advertising platforms with
                customized float designs.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={market_setup}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Market Setup
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Create a compelling presence in local markets with engaging
                stalls and displays.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={product_launch}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Product Launch
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Execute impactful launches that make your product the center of
                attention from day one.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={corporate_events}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Corporate Events
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Organize seamless corporate events that leave a lasting
                impression on attendees.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={marketing_survey}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Marketing Survey
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Gather valuable consumer insights through detailed and
                professional marketing surveys.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={test_drive}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Test Drive Event
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Let customers experience your product firsthand with
                well-coordinated test drive events.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={residential}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Residential Campaign
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Connect directly with communities through personalized,
                on-ground residential campaigns.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={digital_marketing}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Digital Marketing
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Boost your online presence with expert campaigns on YouTube,
                Facebook, Instagram, and Google Ads.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={creative_design}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Creative Designs
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Stand out with stunning visuals and creative designs tailored to
                your brand's identity.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={holding}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Holdings Pan India
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Showcase your brand with high-quality, durable holdings that
                make a statement.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="w-[265px] md:w-[285px] lg:w-[425px] xl:w-[632px] h-[210px] lg:h-[325px] xl:h-[398px] rounded-2xl"
                src={wall_wrap}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Wall Wrap
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Turn walls into branding opportunities with vibrant, large-scale
                wall wraps.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={flex_banner}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Flex and Banners
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Deliver your message loud and clear with high-quality flex and
                banner printing.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={mug_t_shirt}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Mug and T-Shirt Printing
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Promote your brand with customized mugs and t-shirts that
                resonate with your audience.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={leaflets}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Leaflets and Look-Walkers
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Distribute your message effectively with designed leaflets and
                eye-catching look-walker campaigns.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={rwa_activations}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                RWA Activation
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[518px] mt-2 lg:mt-3 xl:mt-5">
                Engage residents directly through interactive and impactful RWA
                activations
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-5 xl:mt-[52px]">
            <div className="w-full h-full max-w-[310px] lg:max-w-[470px] xl:max-w-[680px] min-h-[330px] lg:min-h-[540px] xl:min-h-[623px] bg-[#ffffff] rounded-2xl transition duration-1000 hover:shadow-lightblack p-3 lg:p-6">
              <img
                className="max-w-[265px] md:max-w-[285px] lg:max-w-[425px] xl:max-w-[632px] min-h-[210px] lg:min-h-[325px] rounded-2xl"
                src={mall_activations}
                alt=""
              />
              <h2 className="font-roboto font-semibold text-lg lg:text-[30px] xl:text-[32px] text-light_black leading-121 mt-3 lg:mt-5 xl:mt-9 mb-0">
                Mall Activation
              </h2>
              <p className="font-dm font-normal text-base lg:text-lg leading-121 text-light_black max-w-[548px] mt-2 lg:mt-3 xl:mt-5">
                Capture high footfall areas with engaging mall activation
                campaigns that drive awareness and interaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurActivity;
